import classnames from "classnames";
import Button from "../button";
import styles from "./styles.module.css";
import { Link } from "react-router-dom";

interface CardProps {
  description: string;
  disabled?: boolean;
  src: string;
  alt: string;
  giftId: number;
  href: string;
  handleChooseGift?: (id: number) => void;
}

const Card = ({
  description,
  disabled = false,
  src,
  alt,
  giftId,
  href,
  handleChooseGift,
}: CardProps) => {
  const onClick = () => {
    if (handleChooseGift) handleChooseGift(giftId);
  };

  console.log("href", href);
  return (
    <div
      className={classnames(styles.cardWrapper, {
        [styles.disabled]: disabled,
      })}
    >
      <div className={styles.cardBg}>
        <img src={src} alt={alt} />
      </div>
      <div className={styles.cardContent}>
        <Link to={href} target="_blank">
          <h3>{description}</h3>
        </Link>
        <Button
          theme="outlined"
          disabled={disabled}
          label={disabled ? "Reservado" : "Reservar"}
          data-id={String(giftId)}
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export default Card;
