import { ChangeEvent } from "react";
import styles from "./styles.module.css";
import InputMask from "react-input-mask";
import classnames from "classnames";

type InputProps = {
  label: string;
  name: string;
  value?: undefined | string;
  error?: null | string;
  placeholder?: null | string;
  onChange: (name: string, value: string) => void;
  mask?: string;
};

const Input = ({
  label,
  name,
  value,
  error = null,
  placeholder = null,
  onChange,
  mask,
}: InputProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    onChange(name, value);
  };
  return (
    <div className={styles.inputContent}>
      <span className={styles.label}>{label}</span>
      <div className={classnames({ [styles.errorInput]: !!error })}>
        <InputMask
          mask={mask ?? ""}
          value={value}
          onChange={handleChange}
          type="text"
          name={name}
        />
        {error && <span className={styles.error}>{error}</span>}
        {!error && placeholder && (
          <span className={styles.placeholder}>{placeholder}</span>
        )}
      </div>
    </div>
  );
};

export default Input;
