import fairycore from '@assets/images/dress/fairycore.jpg';
import leiteiro from '@assets/images/dress/leiteiro.jpg';
import cape from '@assets/images/dress/cape.jpg';
import cami from '@assets/images/dress/cami.jpg';
import frontal from '@assets/images/dress/frontal.jpg';
import manga from '@assets/images/dress/manga.jpg';
import bolsa from '@assets/images/dress/bolsa.jpg';
import dama from '@assets/images/dress/dama.jpg';
import fada from '@assets/images/dress/fada.jpg';
import punho from '@assets/images/dress/punho.jpg';
import manto from '@assets/images/dress/manto.jpg';
import colar from '@assets/images/dress/colar.jpg';
import chiffon from '@assets/images/dress/chiffon.jpg';
import pena from '@assets/images/dress/pena.jpg';
import chapeu from '@assets/images/dress/chapeu.jpg';
import hippie from '@assets/images/dress/hippie.jpg';
import flauta from '@assets/images/dress/flauta.jpg';
import combo from '@assets/images/dress/combo.jpg';
import camiseta from '@assets/images/dress/camiseta.jpg';
import ombro from '@assets/images/dress/ombro.jpg';
import goth from '@assets/images/dress/goth.jpg';
import bordado from '@assets/images/dress/bordado.jpg';
import renda from '@assets/images/dress/renda.jpg';
import cinto from '@assets/images/dress/cinto.jpg';

export {}

export const DRESS_SUGGESTIONS = [
    { label:'Fairycore Bloco De Cores Cadarço Ombro Frio Bainha Do Lenço Vestido', src: fairycore, href: 'https://br.shein.com/ROMWE-Fairycore-Colorblock-Lace-Up-Cold-Shoulder-Hanky-Hem-Dress-p-23234205.html?mallCode=1' },
    { label:'Vestido leiteiro com decote Cache-coeur e laço frontal e busto franzido', src: leiteiro, href: 'https://br.shein.com/SHEIN-MOD-Lace-Up-Front-Ruched-Bust-Milkmaid-Dress-p-18543079.html?mallCode=1&imgRatio=3-4' },
    { label:'Fairycore Cape Vintage Rainha Elfa da Floresta Fashion Feminino', src: cape, href: 'https://br.shein.com/ROMWE-Fairycore-Women-Fashionable-Forest-Elf-Queen-Vintage-Cape-p-33007393.html?mallCode=1&imgRatio=3-4' },
    { label:'Fairycore Top Cami Com Renda Em Cor Sólida E Estampa De Folhas', src: cami, href: 'https://br.shein.com/ROMWE-Fairycore-Solid-Color-Lace-Trim-Shirred-Cami-Top-With-Leaf-Print-p-29922550.html?mallCode=1&imgRatio=3-4' },
    { label:'Camisa masculina com cadarço frontal', src: frontal, href: 'https://br.shein.com/Men-Lace-Up-Front-Shirt-p-17695899.html?imgRatio=3-4' },
    { label:'Camisa Social Masculina Manga Longa Lisa Com Botão Blusa Social Importado Confortável', src: manga, href: 'https://br.shein.com/Men-Shirts-p-35387911.html?&main_attr=27_739&mallCode=2' },
    { label:'1 Peça Bolsa Marrom Vintage para Costas com Suporte de Espada, Cinto Gótico, Acessório para Apresentações para Arnês de Halloween', src: bolsa, href: 'https://br.shein.com/1pc-Brown-Vintage-Back-Carrying-Sword-Bag-Gothic-Belt-Performance-Prop-For-Halloween-Harness-p-23064291.html?mallCode=1&imgRatio=1-1' },
    { label:'Conjunto Completo de Fantasia de Dama Renascentista com Espartilho, Cinto de Cintura, Porta-Moedas, Frasco de Poção Luminosa e Saia Erguida - Ideal para Cosplay Medieval e Festivais Históricos', src: dama, href: 'https://br.shein.com/Complete-Renaissance-Fair-Maiden-Costume-Set-With-Corset-Waistband-Belt-Coin-Purse-Alight-Potion-Bottle-And-Skirt-Hike-Ideal-For-Medieval-Cosplay-And-Historical-Festivals-p-51925002.html?mallCode=1' },
    { label:'1 peça Asas de Fada Borboleta Dourada, Adereços de Fantasia para Festa, Cosplay e Apresentação para Todo Tipo de Fantasia de Feriado, Halloween', src: fada, href: 'https://br.shein.com/1pc-Gold-Foil-Butterfly-Fairy-Wings-Girls-Party-Cosplay-Performance-Props-For-All-Kinds-Of-Holiday-Dress-Up-Halloween-p-35067052.html?mallCode=1&imgRatio=3-4' },
    { label:'Protetores de Punho Vintage Medieval Estampados, Adereços para Palco de COSPLAY, Acessórios de Moda Protetores de Braço de Cavaleiro', src: punho, href: 'https://br.shein.com/Medieval-Vintage-Embossed-Wrist-Guards-COSPLAY-Stage-Props-Knight-Arm-Guards-Fashion-Accessories-p-47629894.html?mallCode=1&imgRatio=1-1' },
    { label:'1 peça Manto Preto Unissex Adulto para Halloween, Fantasia de Bruxa Vampiro Demônio, Estilo Medieval com Capuz, Mistura de Lã', src: manto, href: 'https://br.shein.com/1pc-Unisex-Adult-Black-Halloween-Cloak-Robe-Wizard-Witch-Vampire-Demon-Cosplay-Costume-Medieval-Style-With-Hood-Wool-Blend-Material-p-41081983.html?mallCode=1' },
    { label:'1 Peça Colar Gargantilha com Flor de Rosa Preta, Decoração com Borla, Formato de Gota de Água, Adequado para Uso Diário, Festa, Halloween e Dia dos Namorados', src: colar, href: 'https://br.shein.com/1pc-Women-s-Black-Rose-Flower-Tassel-Decor-Water-Drop-Shaped-Choker-Necklace-Suitable-For-Daily-Wear-And-Party-Halloween-Valentine-s-Day-Valentines-p-23980952.html?mallCode=1' },
    { label:'1 Peça Manga de Chiffon Preta com Mangas Bufantes e Elástico Lúgubre 2 para Acessório de Manga de Noiva para Festa de Halloween', src: chiffon, href: 'https://br.shein.com/1pc-Black-Chiffon-With-Puff-Sleeves-Grim-2-Elastic-For-Halloween-Party-Bride-Sleeve-Accessory-p-43721322.html?mallCode=1' },
    { label:'1 Peça Lenço de Pena Natural, Acessório de Ombro de Pena em Estilo Gótico Sexy Punk para Mulheres', src: pena, href: 'https://br.shein.com/1pc-Natural-Feather-Scarf-Sexy-Punk-Gothic-Style-Feather-Shoulder-Accessory-For-Women-p-17570850.html?mallCode=1&imgRatio=3-4' },
    { label:'Goth Chapéu sólido minimalista', src: chapeu, href: 'https://br.shein.com/ROMWE-Goth-Minimalist-Solid-Hat-Valentines-p-12863030.html?mallCode=1' },
    { label:'Hippie Suéter Cropped Vazado de Crochê de Verão, Escola', src: hippie, href: 'https://br.shein.com/ROMWE-Hippie-Summer-Crochet-Hollow-Out-Crop-Sweater-School-p-10958980.html?src_identifier=st%3D2%60sc%3Dfantasia%20monge%60sr%3D0%60ps%3D1&src_module=search&src_tab_page_id=page_risk_crawler_block1737148671013&mallCode=1&pageListType=4&imgRatio=3-4' },
    { label:'Camiseta com Laço e Manga Flauta Preta Feminina, Camiseta de Outono/Inverno, Bruxa, Diabo, Roupas Hippie, Fantasia de Líder de Torcida, Baile de Máscaras, Fantasia de Mulher-Gato, Fada, Tops Sexy Femininos', src: flauta, href: 'https://br.shein.com/SHEIN-MOD-Women-s-Black-Flare-Sleeve-Bow-Lace-T-Shirt-Fall-Winter-T-Shirt-Witch-Devil-Hippie-Clothes-Cheerleader-Costume-Masquerade-Ball-Cat-Woman-Costume-Fairy-Sexy-Women-Tops-p-41618210.html?src_identifier=st%3D2%60sc%3Dbruxa%20adultas%60sr%3D0%60ps%3D1&src_module=search&src_tab_page_id=page_goods_detail1737406206290&mallCode=1&pageListType=4&imgRatio=3-4' },
    { label:'Novo Conjunto Combo Vintage de Colar com Engrenagens de Estilo Steampunk de Design Escuro, Colar de Renda, Pulseira de Laço Retrô para Halloween', src: combo, href: 'https://br.shein.com/New-Vintage-Dark-Design-Steampunk-Style-Gear-Necklace-Lace-Collar-Necklace-Retro-Bow-Bracelet-Combo-Set-Halloween-p-38861952.html?mallCode=1' },
    { label:'Fairycore Regata Assimétrica com Babados e Estampa de Paisley para Mulheres, Ideal para Férias de Verão', src: camiseta, href: 'https://br.shein.com/ROMWE-Fairycore-Women-s-Paisley-Print-Ruffle-Asymmetrical-Hem-Halter-Top-For-Summer-Vacation-p-34565004.html?&imgRatio=3-4&main_attr=27_118&mallCode=1' },
    { label:'Fairycore Fora Do Ombro Bainha Assimétrica Vestido Sem Cinto', src: ombro, href: 'https://br.shein.com/ROMWE-Fairycore-Off-Shoulder-Asymmetrical-Hem-Dress-Without-Belt-p-18304039.html?mallCode=1' },
    { label:'Goth Vestido Assimétrico com Mangas Sino, Laço na Cintura e Estampa de Lua, Sol, Estrelas e Satã com Padrão Floral para Mulheres', src: goth, href: 'https://br.shein.com/ROMWE-Goth-Moon-Sun-Stars-And-Satan-Printed-Waist-Bow-Tie-Women-s-Bell-Sleeve-Asymmetrical-Hem-Dress-With-Floral-Pattern-p-34270798.html?mallCode=1' },
    { label:'Fairycore Bordado De Folhas Crop Fantasia Cncapuzado Capa', src: bordado, href: 'https://br.shein.com/ROMWE-Fairycore-Leaf-Embroidery-Crop-Costume-Hooded-Cape-p-22919138.html?mallCode=1' },
    { label:'Fairycore Bordado De Folhas Crop Fantasia Cncapuzado Capa', src: renda, href: 'https://br.shein.com/ROMWE-Fairycore-Lace-Up-Front-Lace-Cami-Top-p-24565911.html?&main_attr=27_334&mallCode=1' },
    { label:'Bolsa de Cinto de Couro Vegano Marrom, Cinto Medieval, Vintage Renascença Renfaire Larp, Pochete Florestal, Fantasia Cosplay, Acessórios', src: cinto, href: 'https://br.shein.com/Brown-Vegan-Leather-Belt-Pouch-Medieval-Belt-Vintage-Renaissance-Renfaire-Larp-Fanny-Pack-Forest-Costume-Cosplay-Props-p-51386914.html?mallCode=1&imgRatio=3-4' },
]