import { useEffect, useState } from "react";
import classnames from "classnames";

import useDeviceDetect from "../../hooks/useDeviceDetect";
import usePrevious from "../../hooks/usePreveous";
import useGoOutEffectHook from "../../hooks/useGoOutEffect";

import { ReactComponent as Logo } from "@assets/logo/header-logo.svg";

import styles from "./styles.module.css";
import { HEADER_ITENS } from "./constants";

const Header = () => {
  const [scrollPos, setScrollPos] = useState<number>(0);
  const [isOpen, setIsOpen] = useState(false);
  const wasOpen = usePrevious(isOpen);
  const home = document.querySelector("#home")?.clientHeight;
  const { isMobile } = useDeviceDetect();
  const [isVisible, isGoingOut, triggerGoOutEffect] = useGoOutEffectHook(
    isOpen,
    400
  );

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPos(position);
  };

  const onToggleMenu = () => {
    setIsOpen((prev) => {
      if (wasOpen && prev) triggerGoOutEffect();
      return !prev;
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header className={styles.headerBg}>
        {isMobile ? (
          <>
            <div className={styles.mobileHeader}>
              <button
                type="button"
                onClick={onToggleMenu}
                className={classnames(styles.sandwichMenu, {
                  [styles.openMenu]: isOpen,
                  [styles.closeMenu]: !isOpen && wasOpen,
                })}
              >
                <div className={styles.topBar}></div>
                <div className={styles.centerBar}></div>
                <div className={styles.bottomBar}></div>
              </button>
              <a href="#home">
                <Logo />
              </a>
            </div>
            {isVisible && (
              <ul
                className={classnames({
                  [styles.showMenu]: isVisible,
                  [styles.hideMenu]: isGoingOut,
                })}
              >
                {HEADER_ITENS.filter((item) => item.label).map((item) => (
                  <a
                    onClick={onToggleMenu}
                    href={item.href}
                    key={"key-logo" + item.label}
                  >
                    <li>{item.label}</li>
                  </a>
                ))}
              </ul>
            )}
          </>
        ) : (
          <nav>
            <ul className={styles.headerWrapper}>
              {HEADER_ITENS.map((item) => {
                if (item.logo)
                  return (
                    <a href={item.href} key={"key-logo" + item.label}>
                      <li>
                        <item.logo />
                      </li>
                    </a>
                  );

                return (
                  <a key={"key-" + item.href} href={item.href}>
                    <li>{item.label}</li>
                  </a>
                );
              })}
            </ul>
          </nav>
        )}
      </header>
      {home && scrollPos >= home && <div className={styles.spacing}></div>}
    </>
  );
};

export default Header;
