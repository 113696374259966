import { useMemo, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";

import {
  chooseGifts,
  FormatedGiftData,
  getAllGifts,
} from "./libs/services/gifts";

import Element from "@components/element";
import Header from "./components/header";
import Timer from "./components/timer";
import Card from "./components/card";
import Snackbar from "./components/snackbar";

import { ReactComponent as CheckIcon } from "@assets/icons/check-snackbar-icon.svg";
import Divider from "@assets/images/elements/tribal-divider.svg";
import Navi from "@assets/images/elements/navi.svg";
import Lotus from "./libs/assets/images/lotus-recepcoes.png";
import DressCode from "./libs/assets/images/costume-references.png";

import styles from "./App.module.css";
import Input from "./components/input";
import Button from "./components/button";
import AmaountBlock from "./components/amount-block";
import { confirmGuest, FormatedGuestData } from "./libs/services/guests";
import useDeviceDetect from "./hooks/useDeviceDetect";
import classnames from "classnames";
import { DRESS_SUGGESTIONS } from "./constants";

const GUEST_INITIAL_STATE = {
  name: "",
  adults_number: 1,
  kids_number: 0,
  message: "",
  phone: "",
  email: "",
};

function App() {
  const { isMobile } = useDeviceDetect();

  const [showSnackBar, setShowSnackBar] = useState(false);
  const [showGiftSnackBar, setShowGiftSnackBar] = useState(false);

  const [guestData, setGuestData] = useState<GuestType>(GUEST_INITIAL_STATE);

  const guestMutation = useMutation({
    mutationFn: (newData: FormatedGuestData) => confirmGuest(newData),
  });

  const giftsMutation = useMutation({
    mutationFn: (newData: FormatedGiftData) => chooseGifts(newData),
  });

  const { data: giftList, refetch } = useQuery({
    queryKey: ["gifts"],
    queryFn: () => {
      return getAllGifts();
    },
  });

  const chooseGiftSuccess = () => {
    console.log("deu bom");
    setShowGiftSnackBar(true);
    refetch();
  };
  const chooseGiftError = () => {
    console.log("deu ruim");
  };

  const handleChooseGift = (id: number) => {
    const item = giftList.find((gift: GiftType) => gift.id === id);
    console.log("item", item);

    giftsMutation.mutate(
      { giftIds: [String(item.id)] },
      { onSuccess: chooseGiftSuccess, onError: chooseGiftError }
    );
  };

  const onChangeInput = (name: string, value: string) => {
    setGuestData((prev) => ({ ...prev, [name]: value }));
  };

  const validateGuestForm = useMemo(() => {
    let error = { name: "", email: "", adults_number: "" };
    const MANDATORY_FIELDS = ["name", "phone"];

    Object.keys(guestData).forEach((key) => {
      if (
        //@ts-ignore
        !guestData[key] &&
        MANDATORY_FIELDS.includes(key)
      ) {
        let errorMessage = "Por favor adicione um ";
        if (key === "name") errorMessage = errorMessage + "nome e sobrenome";
        if (key === "phone") errorMessage = errorMessage + "telefone";
        error = { ...error, [key]: errorMessage };
      }
    });

    return error;
  }, [guestData]);

  const onConfirmPresenceSuccess = () => {
    setShowSnackBar(true);
    setGuestData(GUEST_INITIAL_STATE);
  };

  const onConfirmPresenceFailure = () => {
    console.log("error", guestMutation.error);
  };

  const onConfirmPresence = () => {
    const error = validateGuestForm;
    setGuestData((prev) => ({ ...prev, error }));
    const canSubmit =
      Object.keys(error).filter(
        //@ts-ignore
        (key) => error[key]
      ).length < 1;

    if (canSubmit) {
      guestMutation.mutate(guestData, {
        onSuccess: onConfirmPresenceSuccess,
        onError: onConfirmPresenceFailure,
      });
    }
  };

  return (
    <main>
      <Header />
      <section className={styles.home} id="home">
        {isMobile && (
          <Element
            src={Navi}
            size={{ width: 91, height: 88 }}
            position={{ top: 320, right: 30 }}
          />
        )}
        <span>Aniversário • XV anos (x2)</span>
        <h1>Andressa Belém</h1>
        <h3>Lótus Recepções</h3>
        <h4>07/03/2025</h4>
      </section>
      <section
        className={classnames(styles.countDown, styles.sectionWithDivider)}
      >
        <div>
          <Element
            src={Divider}
            size={
              isMobile ? { width: 104, height: 35 } : { width: 141, height: 48 }
            }
            position={{ top: 0, horizontalCenter: true }}
          />
          <h2>Contagem Regressiva</h2>
          <Timer date={{ day: "07", month: "03", year: "2025" }} />
        </div>
      </section>
      <section className={styles.note}>
        <div>
          <h2>
            Queridos amigos e familiares, <br /> sejam bem vindos ao site da
            festa!
          </h2>
          <p>
            Essa festa é uma celebração especial, um momento único para
            comemorar meus 15 anos... 15 anos depois!
          </p>
          <p>
            Aqui iremos compartilhar informações sobre o local, vestimenta e
            ideias de presente.
          </p>
          <p>
            O tema escolhido é fantasia medieval, trazendo à tona um universo de
            magia, mistérios e aventuras.
          </p>
          <p>
            Ah, é importante também{" "}
            <a href="#confirm">confirmar sua presença.</a> É só clicar no menu
            "Confirme sua Presença" e preencher os dados.
          </p>
          <p>Aguardo vocês nesse grande dia!</p>
        </div>
      </section>
      <section
        className={classnames(styles.ceremony, styles.sectionWithDivider)}
        id="local"
      >
        <div>
          <Element
            src={Divider}
            size={
              isMobile ? { width: 104, height: 35 } : { width: 141, height: 48 }
            }
            position={{ top: 0, horizontalCenter: true }}
          />
          <h2>Local</h2>
          <div className={styles.ceremonyContent}>
            <p>
              Contamos com vocês dia <br />
              <b>07 de Março de 2025, às 17h</b>
            </p>

            <div className={styles.linkLotus}>
              <p> Lótus Recepções</p>
              <address>
                Endereço: Av. Tropical, 170 - Pitimbú, Natal - RN, 59066-360
              </address>
              <Link
                to={
                  "https://www.google.com/maps/place/L%C3%B3tus+Recep%C3%A7%C3%B5es/@-5.8668635,-35.2247228,17z/data=!3m1!4b1!4m6!3m5!1s0x7b25505902cda65:0xd46b84153c0323af!8m2!3d-5.8668635!4d-35.2221479!16s%2Fg%2F11pdr93ykb?entry=tts&g_ep=EgoyMDI1MDExNS4wIPu8ASoASAFQAw%3D%3D"
                }
                target="_blank"
              >
                Clique aqui para ver a localização no Google Maps
              </Link>
            </div>
          </div>
          <img src={Lotus} alt="Lótus Recepções" />
        </div>
      </section>
      <section
        className={classnames(styles.dressCode, styles.sectionWithDivider)}
        id="dressCode"
      >
        <div>
          <Element
            src={Divider}
            size={
              isMobile ? { width: 104, height: 35 } : { width: 141, height: 48 }
            }
            position={{ top: 0, horizontalCenter: true }}
          />
          <h2>Vestimenta </h2>
          <div className={styles.dresscodeContent}>
            <p>
              Neste universo fantasia, onde a floresta encantada encontra a era
              medieval, que tal deixar sua vestimenta transportar você para um
              reino cheio de criaturas místicas, cavaleiros corajosos, fadas
              enigmáticas e nobres de alta linhagem?
            </p>
            <p>
              Solte sua imaginação e considere possibilidades como elfos,
              dragões, bruxas, feiticeiros, princesas e príncipes medievais.
            </p>
            <p>
              Qualquer criatura fantástica que habite sua imaginação também é
              bem-vinda, o importante é que sua fantasia esteja imersa nesse
              universo de magia e aventura.
            </p>
            <p>
              Obs: Sendo uma floresta encantada, a festa será ao ar livre com
              chão de grama – cuidado com saltos finos e roupas muito quentes.
            </p>
          </div>
          <img src={DressCode} alt="Dress code do casamento" />

          <div className={styles.utils}>
            <h3>Links úteis</h3>
            <p>
              Precisa de inspiração para montar sua fantasia medieval? Aqui você
              encontra sugestões de peças e acessórios, com links que vão
              facilitar sua jornada.
            </p>

            <ul className={styles.suggestGrid}>
              {DRESS_SUGGESTIONS.map(({ label, src, href }: any) => (
                <li className={styles.suggestCard}>
                  <img src={src} alt={label} />
                  <Link to={href}>
                    <span>{label}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
      <section
        id="giftList"
        className={classnames(
          styles.giftListSection,
          styles.sectionWithDivider
        )}
      >
        <div>
          <Element
            src={Divider}
            size={
              isMobile ? { width: 104, height: 35 } : { width: 141, height: 48 }
            }
            position={{ top: 0, horizontalCenter: true }}
          />
          <div className={styles.pixContainer}>
            <h2>Ideias de presentes</h2>
            <p>
              Preparei essa lista pra ajudar, caso esteja na dúvida sobre
              presentes.
            </p>
            <p>
              Mas o que realmente importa é a sua presença para celebrar esse
              momento especial comigo!
            </p>
            <p>
              Para evitar presentes repetidos, basta clicar para reservar o item
              escolhido e levá-lo no dia da festa.
            </p>
          </div>
          <div className={styles.giftsContainer}>
            <ul className={styles.giftList}>
              {giftList?.map(
                ({ id, name, cost, photo_src, was_gifted, url }: GiftType) => (
                  <li key={id + cost}>
                    <Card
                      description={name}
                      src={photo_src}
                      alt={name}
                      disabled={!!was_gifted}
                      giftId={id}
                      handleChooseGift={handleChooseGift}
                      href={url}
                    />
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </section>
      <section
        id="confirm"
        className={classnames(styles.confirm, styles.sectionWithDivider)}
      >
        <div>
          <Element
            src={Divider}
            size={
              isMobile ? { width: 104, height: 35 } : { width: 141, height: 48 }
            }
            position={{ top: 0, horizontalCenter: true }}
          />
          <div className={styles.confirmContainer}>
            <h2>Confirme sua presenca</h2>
          </div>
          <div className={styles.confirmForm}>
            <Input
              label="Nome e sobrenome"
              name="name"
              onChange={onChangeInput}
              value={guestData.name}
              error={guestData.error?.name}
            />
            <AmaountBlock
              name="adults_number"
              label="Quantidade de adultos"
              placeholder="+ 18 anos"
              onChange={onChangeInput}
              initialValue={1}
              value={guestData.adults_number}
            />
            <AmaountBlock
              name="kids_number"
              label="Quantidade de crianças"
              placeholder="0 - 12 anos"
              onChange={onChangeInput}
              value={guestData.kids_number}
            />
            {/*  <Input
              label="E-mail"
              name="email"
              onChange={onChangeInput}
              value={guestData.email}
              error={guestData.error?.email}
              placeholder="Vocês receberão a confirmação da presença neste e-mail"
            /> */}
            <Input
              label="Telefone"
              name="phone"
              onChange={onChangeInput}
              value={guestData.phone}
              error={guestData.error?.phone}
              mask="(99) 9 9999-9999"
            />
            <Input
              label="Quer deixar um comentário? (Opcional)"
              name="message"
              onChange={onChangeInput}
              value={guestData.message}
            />
            <Button label="Confirmar presença" onClick={onConfirmPresence} />
          </div>
        </div>
        {showSnackBar && (
          <Snackbar
            onClose={() => {
              setShowSnackBar(false);
            }}
            Icon={CheckIcon}
            label="Sua presença foi confirmada"
          />
        )}
        {showGiftSnackBar && (
          <Snackbar
            onClose={() => {
              setShowGiftSnackBar(false);
            }}
            Icon={CheckIcon}
            label="Presente reservado"
          />
        )}
      </section>
    </main>
  );
}

export default App;
